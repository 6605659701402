module.exports = [{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RUSU Welfare Directory","short_name":"RUSU Welfare","description":"Reading University Students' Union's Welfare Directory offers important contact information for a range of student needs and issues.","lang":"en","start_url":"/","background_color":"#ea5616","theme_color":"#f8f9fa","display":"standalone","icon":"static/logo-favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-56171132-3","cookieName":"google-analytics","anonymize":true},"environments":["production"]},
    }]
